<template>
  <div class="container">
    <video class="input_video"></video>
    <canvas class="output_canvas" width="1280px" height="720px"></canvas>
  </div>
</template>


<script>
export default {
  name: "EmojiMimic",
  methods:{
  },
  created() {
  }
}
</script>

<style scoped>

</style>
