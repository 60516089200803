<template>
  <div class="squad-page-container">
    <div id="debug-console">
      message
    </div>
  </div>
</template>

<script>
export default {
  name: "SquadView",
  created() {
    window.addEventListener('devicemotion', function(event) {
      document.getElementById("debug-console").innerText = event.accelerationIncludingGravity.x;
      console.log(event.acceleration.x + ' m/s2');
    });

  }
}
</script>

<style scoped>

</style>
